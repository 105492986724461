*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.containerLanding,
.containerLanding-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerLanding {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .containerLanding {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .containerLanding {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .containerLanding {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .containerLanding {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row-landing {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row-landing > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.collapse:not(.show) {
  display: none;
}

.show {
  display: block !important; /* or any other appropriate style to show the menu */
}

.navbar-landing {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar-brand-landing {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand-landing:hover,
.navbar-brand-landing:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.d-flex-landing {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.order-1 {
  order: 1 !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .text-sm-start {
    text-align: left !important;
  }
}

@media (min-width: 992px) {
  .order-lg-0 {
    order: 0 !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }
}

@font-face {
  font-family: "Kaleko 205 Round W01 Bold";
  src: url("../../public/webfonts/Kaleko205RoundW01Bold.woff2") format("woff2"),
    url("../../public/webfonts/Kaleko205RoundW01Bold.woff") format("woff");
}

@font-face {
  font-family: "Kaleko 205 W00 Book";
  src: url("../../public/webfonts/Kaleko205W00Book.woff2") format("woff2"),
    url("../../public/webfonts/Kaleko205W00Book.woff") format("woff");
}

:root {
  --head-font: "Kaleko 205 Round W01 Bold", sans-serif;
  --body-font: "Kaleko 205 W00 Book", sans-serif;
  --body-color: #ffffff;
  --primary-color: #1c2452;
  --secondary-color: #258c9b;
  --head-color: #0e1730;
  --head-font-color: #ffffff;
  --section-primary-color: #f2f3f9;
  --section-color: #f5f6fc;
  --para-color: #0e1730;
  --border-color: #e67e22;
  --hover-alt-color: #e67e22;
}

html {
  scroll-behavior: smooth;
}

section,
footer {
  overflow-x: hidden;
}

.link-landing,
.link-landing:focus,
.link-landing:hover {
  text-decoration: none;
  outline: none;
  color: var(--para-color);
}

.max-un {
  max-width: 95%;
}

.d-rtl {
  direction: rtl;
}

input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border-color);
}

input {
  padding: 10px 20px;
  color: var(--para-color);
  width: 100%;
  font-family: var(--body-font);
  background: var(--bs-white);
  border: 1px solid #eeecf7;
  border-radius: 10px;
}

::placeholder {
  color: rgba(12, 38, 110, 0.502);
  opacity: 1;
}

.link-landing {
  display: inline-block;
  color: var(--para-color);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.section-text {
  margin-bottom: 34px;
}

.section-header {
  margin-bottom: 53px;
}

.sub-title {
  color: #1c2452;
  margin-bottom: 30px;
}

.title {
  font-weight: 700;
  margin-bottom: 22px;
}

.xlr {
  font-size: 24px;
  line-height: 36px;
  margin-top: -7px;
}

.mdr {
  font-size: 16px;
  line-height: 22px;
}

.mt-40 {
  margin-top: 40px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-120 {
  padding-top: 120px;
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }

  .header-section-landing {
    background-image: linear-gradient(90deg, #345d92 0%, #1c2452 100%);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-white);
  }
}

@media (max-width: 575px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 45px;
  height: 45px;
  background-color: #1c2452;
  border-radius: 5px;
  color: #fff;
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  transition: all 1s;
  transform: translateY(100%);
}

.scrollToTop i {
  color: var(--bs-white);
}

.accordion-button:focus {
  box-shadow: none;
}

.cmnBtn {
  padding: 10px 30px;
  font-weight: 600;
  text-align: center;
  background: #1c2452;
  color: var(--bs-white);
  transition: 0.3s;
  border-radius: 10px;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cmnBtn:hover {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--para-color);
}

.cmnBtn-orange {
  padding: 10px 30px;
  font-weight: 600;
  text-align: center;
  background: var(--border-color);
  color: var(--bs-white);
  transition: 0.3s;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cmnBtn-orange:hover {
  background: transparent;
  border: 1px solid #1c2452;
  color: var(--para-color);
}

.cmnBtn-orange img {
  margin-left: 10px;
}

[class*="icon-"]:before {
  display: inline-block;
  font-family: "arafat font";
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.floatingButton {
  position: fixed;
  bottom: 80px;
  right: 20px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1000;
}

.header-section-landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #1c2452;
  border-bottom: 1px solid #6c6ea6;
}

.header-section-landing.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(90deg, #345d92 0%, #1c2452 100%);
  background-color: #1c2452;
  border-color: transparent;
}

.header-section-landing .navbar-toggler {
  padding-right: 0;
  display: none;
  border: none;
}

.header-section-landing .navbar-toggler:focus {
  box-shadow: none;
}

.header-section-landing .navbar-toggler i {
  color: var(--head-alt-color);
}

.header-section-landing .navbar-landing {
  padding: 2px 15px;
}

.header-section-landing .navbar-landing .navbar-collapse .right-area .cmnBtn {
  background-color: var(--secondary-color);
  color: var(--head-font-color);
  margin-left: 40px;
  transition: all 0.4s ease;
}

.header-section-landing
  .navbar-landing
  .navbar-collapse
  .right-area
  .cmnBtn:hover {
  background-color: var(--hover-alt-color);
}

@media (max-width: 1799px) {
  .banner-section.index .overlayLanding .shape-area img {
    width: 10%;
  }

  .counter-section::before {
    width: 94%;
    left: 3%;
  }
}

@media (max-width: 1499px) {
  .banner-section .overlayLanding .shape-area .obj-1,
  .banner-section .overlayLanding .shape-area .obj-4 {
    display: none;
  }
}

@media (max-width: 1399px) {
  .banner-section
    .overlayLanding
    .banner-content
    .containerLanding
    .content-shape
    .obj-1 {
    left: 45%;
  }

  .banner-section
    .overlayLanding
    .banner-content
    .main-content
    .bottom-area
    .cmnBtn {
    margin-bottom: 20px;
  }

  .banner-section
    .overlayLanding
    .banner-content
    .main-content
    .bottom-area
    .cmnBtn-orange {
    margin-bottom: 20px;
  }
}

@media (max-width: 1199px) {
  h2 {
    font-size: 43px;
    line-height: 55.9px;
    margin-top: -10px;
  }

  .banner-section .overlayLanding .banner-content {
    padding: 220px 0 20px;
  }

  .banner-section
    .overlayLanding
    .banner-content
    .containerLanding
    .content-shape
    img {
    display: none;
  }

  .app-download .image-area {
    margin-top: 60px;
    text-align: center;
  }

  .app-download .image-area img {
    width: 80%;
  }

  .how-it-works .single-item::after {
    right: calc(-10% - 10px);
    top: calc(60% - 20px);
  }

  .how-it-works .cus-mar {
    margin-bottom: -30px;
  }

  .how-it-works .single-item {
    background-color: var(--body-color);
    margin-bottom: 30px;
  }

  .testimonials .slick-initialized .slick-slide {
    opacity: 1;
  }

  .faqs-section .accordion .accordion-item .accordion-button {
    padding-right: 70px;
  }
}

@media (max-width: 991px) {
  .section-header {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 20px;
  }

  .xlr {
    font-size: 18px;
    line-height: 25.5px;
  }

  .header-section-landing .navbar-toggler {
    display: inline-block;
    color: white;
  }

  .header-section-landing .navbar-landing .navbar-brand-landing {
    margin-top: -5px;
    width: 35%;
  }

  .header-section-landing .navbar-landing .navbar-brand-landing img {
    max-width: initial;
  }

  .header-section-landing .navbar-landing .navbar-collapse .right-area {
    margin-left: 0;
  }

  .banner-section .overlayLanding .banner-content .send-money .currency-area {
    padding: 15px 20px;
  }

  .testimonials .testimonials-carousel {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .banner-section .overlayLanding .banner-content {
    padding: 200px 0 20px;
  }

  .counter-section .single-area {
    margin-bottom: 30px;
  }

  .counter-section {
    padding: 50px 30px 10px;
  }

  .banner-section .overlayLanding .banner-content .send-money {
    margin-top: 40px;
  }
}

@media (max-width: 575px) {
  .cmnBtn,
  .cmnBtn-orange,
  input,
  .section-header {
    margin-bottom: 35px;
  }

  .title {
    margin-bottom: 15px;
  }

  .xlr {
    font-size: 16px;
    line-height: 22.4px;
  }

  .header-section-landing .navbar-landing {
    padding: 2px 15px;
    overflow-x: hidden;
  }

  .banner-section .overlayLanding .banner-content {
    padding: 170px 0 20px;
  }

  .footer-section .footer-area .menu-item {
    margin-bottom: 20px;
  }

  .footer-section .footer-area .menu-item li {
    margin: 0 10px !important;
  }

  .counter-section {
    padding: 40px 30px 5px;
  }

  .how-it-works .col-6 {
    width: 70% !important;
    margin: auto;
  }

  .how-it-works .single-item h5 {
    margin: 12px 0 16px;
  }
}

@media (max-width: 480px) {
  .footer-section .menu-item {
    display: grid;
    justify-content: center;
    text-align: center;
  }

  .faqs-section .accordion .accordion-item .accordion-button {
    padding: 15px 15px;
    padding-right: 70px;
  }

  .faqs-section .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 15px 15px;
  }

  .faqs-section .accordion .accordion-item .accordion-button::after {
    right: 20px;
  }

  .faqs-section .accordion .accordion-item .accordion-button::before {
    right: 15px;
  }

  .testimonials .single-slide .single-content {
    padding: 24px 15px 20px;
  }
}

@media (max-width: 375px) {
  .banner-section
    .overlayLanding
    .banner-content
    .send-money
    .calculation
    .single-area
    .left-area {
    min-width: 60%;
  }
}

.menu-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .menu-container {
    flex-direction: row;
  }
}

/*! CSS Used from: http://localhost:3000/_next/static/css/app/(pages)/(index)/page.css?v=1712177340837 */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-initialized .slick-slide {
  display: block;
}

/*! CSS Used keyframes */
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(-5px, -5px);
  }

  66% {
    transform: translate(5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(5px, -5px);
  }

  66% {
    transform: translate(-5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "arafat font";
  src: url("http://localhost:3000/webfonts/arafat-font.eot");
  src: url("http://localhost:3000/webfonts/arafat-font.eot?#iefix")
      format("embedded-opentype"),
    url("http://localhost:3000/webfonts/arafat-font.woff") format("woff"),
    url("http://localhost:3000/webfonts/arafat-font.ttf") format("truetype"),
    url("http://localhost:3000/webfonts/arafat-font.svg#arafat-font")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.animated-landing {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInDown {
  animation-name: fadeInDown;
}

/*! CSS Used from: http://localhost:3000/_next/static/css/app/layout.css?v=1712177340837 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.link-landing {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

.link-landing:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

.link-landing,
.link-landing:focus,
.link-landing:hover {
  text-decoration: none;
  outline: none;
  color: var(--para-color);
}

::placeholder {
  color: rgba(12, 38, 110, 0.502);
  opacity: 1;
}

.link-landing {
  display: inline-block;
  color: var(--para-color);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.cmnBtn {
  padding: 10px 30px;
  font-weight: 600;
  text-align: center;
  background: #1c2452;
  color: var(--bs-white);
  transition: 0.3s;
  border-radius: 10px;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cmnBtn:hover {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--para-color);
}

.header-section-landing .navbar-landing .navbar-collapse .right-area .cmnBtn {
  background-color: var(--secondary-color);
  color: var(--head-font-color);
  margin-left: 40px;
  transition: all 0.4s ease;
}

.header-section-landing
  .navbar-landing
  .navbar-collapse
  .right-area
  .cmnBtn:hover {
  background-color: var(--hover-alt-color);
}

@media (max-width: 991px) {
  .link-landing {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 575px) {
  .link-landing,
  .cmnBtn {
    font-size: 15px;
    line-height: 19px;
  }
}
