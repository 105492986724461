@font-face {
  font-family: "Kaleko 205 Round W01 Bold";
  src: url("/public/webfonts/Kaleko205RoundW01Bold.woff2") format("woff2"),
    url("/public/webfonts/Kaleko205RoundW01Bold.woff") format("woff");
}

@font-face {
  font-family: "Kaleko 205 W00 Book";
  src: url("/public/webfonts/Kaleko205W00Book.woff2") format("woff2"),
    url("/public/webfonts/Kaleko205W00Book.woff") format("woff");
}

:root {
  --head-font: "Kaleko 205 Round W01 Bold", sans-serif;
  --body-font: "Kaleko 205 W00 Book", sans-serif;
  --body-color: #ffffff;
  --primary-color: #1c2452 !important;
  --secondary-color: #258c9b;
  --head-color: #0e1730;
  --head-font-color: #ffffff;
  --section-primary-color: #f2f3f9;
  --section-color: #f5f6fc;
  --para-color: #0e1730;
  --border-color: #e67e22;
  --hover-alt-color: #e67e22;
}

div {
  font-family: var(--body-font);
}
.send-money {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3.464px 24px 0px rgba(106, 105, 194, 0.25);
  .currency-area {
    background-color: #4cb3c35f;
    border-radius: 5px;
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    .right-side {
      .nice-select {
        border-radius: 10px;
        border: none;
        background-color: var(--bs-white);
        padding: 30px 50px 30px 55px;
        display: flex;
        align-items: center;
        &:after {
          border-bottom: 2px solid #0c266c;
          border-right: 2px solid #0c266c;
          height: 13px;
          right: 18px;
          top: initial;
          width: 13px;
        }
        .current {
          position: relative;
          display: flex;
          align-items: center;
          font-weight: 600;
          &::before {
            position: absolute;
            content: "";
            width: 30px;
            height: 30px;
            left: -40px;
            background-repeat: no-repeat;
          }
        }
        .list {
          width: 100%;
        }
      }
      &.recipient {
        .nice-select {
          .current {
            &::before {
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  .calculation {
    padding: 30px 10px;
    .highlight {
      color: var(--primary-color);
    }
    .head-area {
      img {
        margin-right: 10px;
      }
    }
    .single-area {
      margin-top: 17px;
      .left-area {
        min-width: 50%;
        display: flex;
        align-items: center;
        i {
          font-size: 12px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 18px;
          position: relative;
          &::after {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #edecfb;
            z-index: -1;
          }
        }
      }
    }
  }
}

.currency-area {
  background-color: #4cb3c35f;
  border-radius: 5px;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  .right-side {
    .nice-select {
      border-radius: 10px;
      border: none;
      background-color: var(--bs-white);
      padding: 30px 50px 30px 55px;
      display: flex;
      align-items: center;
      &:after {
        border-bottom: 2px solid #0c266c;
        border-right: 2px solid #0c266c;
        height: 13px;
        right: 18px;
        top: initial;
        width: 13px;
      }
      .current {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 600;
        &::before {
          position: absolute;
          content: "";
          width: 30px;
          height: 30px;
          left: -40px;
          background-repeat: no-repeat;
        }
      }
      .list {
        width: 100%;
      }
    }
    &.recipient {
      .nice-select {
        .current {
          &::before {
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

.right-area {
  margin-left: 0;
}

input,
textarea {
  padding: 10px 20px;
  color: var(--para-color);
  width: 100%;
  font-family: var(--body-font);
  background: var(--bs-white);
  border: 1px solid #eeecf7;
  border-radius: 10px;
}

.shimmer-button {
  overflow: hidden;
  position: relative;
}

.cmn-btn {
  padding: 10px 30px;
  font-weight: 600;
  text-align: center;
  background: var(--primary-color);
  color: var(--bs-white);
  transition: 0.3s;
  border-radius: 10px;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: transparent;
    border: 1px solid var(--border-color);
    color: var(--para-color);
  }
  img,
  i {
    margin-left: 10px;
  }
  &.active {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--para-color);
    &:hover {
      background: var(--primary-color);
      color: var(--bs-white);
    }
  }
}

.mdr {
  font-size: 16px;
  line-height: 22px;
}

.col-xxl-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-xl-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
.justify-content-between {
  justify-content: space-between !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.align-items-center {
  align-items: center !important;
}
